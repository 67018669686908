<template>
  <div class="cover-container d-flex h-100 p-3 mx-auto flex-column">
    <NavComponent />
    <router-view/>
    <FooterComponent />
  </div>
</template>

<script>
import NavComponent from './components/NavComponent.vue'
import FooterComponent from './components/FooterComponent.vue'

export default {
  name: 'App',
  components: {
    NavComponent,
    FooterComponent
  }
}
</script>

<style>
</style>
