<template>
    <footer class="mastfoot mt-auto">
      <div class="inner">
        <p><strong>&copy; 2024 Topsail Software Inc.</strong> <a href="mailto:info@topsailsoftware.ca">Contact us</a>.</p>
      </div>
    </footer>
  </template>
  
  <script>
    export default {
      name: 'FooterComponent',
      methods: {
      },
      computed: {
      },
      mounted(){
      }
    }
  </script>
  
  <style lang="scss" scoped>
  
  </style>