<template>
  <main role="main" class="inner cover">
    <h1 class="cover-heading">A web-centric full-stack software development shop in Nova Scotia, serving the world.</h1>
    <p class="lead">We specialize in the design and development of full-stack database-powered web appliations, progressive web apps (great for mobile!), databases, web sites, articifical intelligence, and general custom development.</p>
    <p class="lead">
      <a href="mailto:contact@topsailsoftware.ca" class="btn btn-lg btn-secondary">Contact Us</a>
    </p>
  </main>
</template>

<script>
export default {
  name: 'HomeView',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
