<template>
    <header class="masthead mb-auto">
      <div class="inner">
        <h3 class="masthead-brand">
          <img class="topsail_nav_logo" src="../assets/logo.png">

          
          Topsail Software Inc.</h3>
        <nav class="nav nav-masthead justify-content-center">

          <span v-if="this.$route.name == '' || this.$route.name == 'home'">
            <router-link class="nav-link active" to="/">Home</router-link>
          </span>
          <span v-else>
            <router-link class="nav-link" to="/">Home</router-link>
          </span>
          <span>&nbsp;&nbsp;&nbsp;</span>
          <span v-if="this.$route.name == 'portfolio'">
            <router-link class="nav-link active" to="/portfolio">Portfolio</router-link>
          </span>
          <span v-else>
            <router-link class="nav-link" to="/portfolio">Portfolio</router-link>
          </span>
        </nav>
      </div>
    </header>
  </template>
  
  <script>
    export default {
      name: 'NavComponent',
      methods: {
      },
      computed: {
      },
      mounted(){
      }
    } 
  </script>
  
  <style>
    .topsail_nav_logo {
      max-width: 65px;
    }
  </style>